import React from "react";
import { Helmet } from "react-helmet";
import { SiteWrapper } from "../components/SiteWrapper";
import LandingPage from "../page-components/LandingPage/LandingPage";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";

export default function HomePage({ location }: { location: any }) {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.HOME}>
      <Seo description="Robotic Imaging provides high quality laser scanning and scan to BIM services to engineers and architects nationwide. Contact us to digitize your real estate in CAD, VR, AR, & 3D." title={""} />
      <Helmet>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-207948217-1"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-207948217-1');
          `}
        </script>

        {/* FaceBook Domain Verification Meta tag */}
        <meta
          name="facebook-domain-verification"
          content="bmwuy0p859368j5mcabehz5zlk7mwk"
        />

        {/* Mailchimp connection */}
        <script id="mcjs">
          {`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/5e3b630974e785838458a8049/1e10d7d9997086bda35a63660.js");`}
        </script>

        {/* Leadfeeder lead tracker https://www.leadfeeder.com/*/}
        <script>
          {`
            (function(ss,ex) { 
              window.ldfdr=window.ldfdr || function() {
                  (ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));
                }; 
              (function(d,s) {
                fs=d.getElementsByTagName(s)[0]; 
                function ce(src){
                  var cs=d.createElement(s); 
                  cs.src=src; cs.async=1; 
                  fs.parentNode.insertBefore(cs,fs); 
                }; 
                ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js');
              })
              (document,'script');
            })('kn9Eq4Rywnr7RlvP')
          `}
        </script>

      </Helmet>

      <LandingPage />
    </SiteWrapper>
  );
}
